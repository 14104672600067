<template>
    <div class="tools-container">
        <el-form :inline="true" :model="formData" class="demo-form-inline">
            <el-form-item label="站点类型" :label-width="'70px'">
                <el-select v-model="formData.siteType" placeholder="站点类型" :style="{width: '200px'}" :size="'small'">
                    <el-option label="全部" :value="''"></el-option>
                    <el-option label="自营" :value="1"></el-option>
                    <el-option label="联营" :value="2"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="班组长" :label-width="'70px'">
                <el-input v-model="formData.operationHeadman" placeholder="班组长" :style="{width: '200px'}" :size="'small'"></el-input>
            </el-form-item>
            <el-form-item label="状态" :label-width="'70px'">
                <el-select v-model="formData.status" placeholder="状态" :style="{width: '100px'}" :size="'small'">
                    <el-option label="全部" :value="''"></el-option>
                    <el-option label="有效" :value="1"></el-option>
                    <el-option label="无效" :value="2"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="所属机构" :label-width="'100px'">
                <el-tree-select 
                    ref="group-select-tree"
                    class="group-select-tree" 
                    v-model="formData.orgId"
                    :selectParams="selectParams" 
                    :treeParams="treeParams" 
                    :size="'small'"
                >
                </el-tree-select>
            </el-form-item>
        </el-form>
        <div class="tool-button-container">
            <el-button type="primary" size="small" @click="dialogVisibleChangeHandel">新建</el-button>
            <!-- <el-button type="primary" size="small">导出</el-button> -->
            <el-button type="primary" size="small" @click="queryHandel">查询</el-button>
        </div>
        <AddSiteModal :dialogVisible="dialogVisible" :beforeClose="dialogVisibleChangeHandel"/>
    </div>
</template>

<script>
import AddSiteModal from './AddSiteModal';
import axios from '@/utils/axios';
import './Tools.less';
export default {
    name: 'Tools',
    components: {
        AddSiteModal
    },
    data() {
        return {
            formData: {
                siteType: '',
                operationHeadman: '',
                orgId: '',
                status: '',
            },
            dialogVisible: false,
            selectParams: {
                multiple: false,
                clearable: true,
                placeholder: '请选择机构归属'
            },
            treeParams: {
                clickParent: true,
                filterable: false,
                'check-strictly': true,
                'default-expand-all': true,
                'expand-on-click-node': false,
                data: [],
                props: {
                    children: 'childs',
                    label: 'orgName',
                    value: 'orgId'
                }
            },
        }
    },
    methods: {
        queryHandel() {
            this.$emit('queryChange', this.formData);
        },
        dialogVisibleChangeHandel() {
            this.dialogVisible = !this.dialogVisible;
            this.$emit('queryChange', this.formData);
        },
        async getOrgData() {
            const userinfo = localStorage.getItem('currentUser')
            const orgId = JSON.parse(userinfo).orgId;
            const rs = await axios.get(`/api/org/getOrgTree/${Number(orgId)}`);
            this.treeParams.data = [rs]
            this.$nextTick(() => {
                this.$refs['group-select-tree'].treeDataUpdateFun(this.treeParams.data);
            });
        }
    },
    mounted() {
        this.getOrgData();
    }
}
</script>