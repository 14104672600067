<template>
    <div :id="`siteMap${mapType}`" class="site-map-container">
    </div>
</template>

<script>
import L from "leaflet/dist/leaflet";
import "leaflet.chinatmsproviders";
import "leaflet/dist/leaflet.css";
import './SiteMap.less';
import icon from 'leaflet/dist/images/marker-icon.png';
export default {
    name: 'SiteMap',
    props: {
        mapType: {
            type: String,
            default: 'add'
        }
    },
    data() {
        return {
            map: null,
            point: null
        }
    },
    methods: {
        initMap() {
            if (this.map) {
                return;
            }
            const normalm = L.tileLayer.chinaProvider("GaoDe.Normal.Map", {
                maxZoom: 18,
                minZoom: 1,
            });
            const normal = L.layerGroup([normalm]);
            const map = L.map(`siteMap${this.mapType}`, {
                center: [29.57754, 106.586177],
                zoom: 20,
                layers: [normal],
                zoomControl: false,
            });
            this.map = map;
            map.on('click', (e) => {
                const { lat, lng } = e.latlng;
                this.getClickPoint(lat, lng);
            });
        },
        getClickPoint(lat, lng) {
            // 添加图标
            const myIcon = L.icon({
                iconUrl: icon,
            });
            if (this.point) {
                this.point.setLatLng([lat, lng]);
            } else {
                this.point = L.marker([lat, lng], { 
                    icon: myIcon
                });
            }
            this.point.addTo(this.map);
            this.$emit('pointChange', [lat, lng]);
        }
    },
    mounted() {
        this.initMap();
    },
    beforeDestroy() {
        this.map.remove();
    }
}
</script>