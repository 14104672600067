<template>
    <div class="site-management-container">
        <div class="table-tools-container">
            <Tools @queryChange="queryChangeHandel" />   
        </div>
        <div class="data-tips-container">
            <img class="tips-icon" :src="require('@/assets/img/volume.png')"/>
            <span>站点总量</span><span style="margin: 0 10px; color: #FF0000;">{{tableData.total}},</span>
            <span>本机构站点总量</span><span style="margin: 0 10px; color: #FF0000;">{{tableData.total}}</span>
        </div>
        <div class="table-content-container">
            <div class="table-content">
                <el-table 
                    style="width: 100%"
                    :data="tableData.records"
                    height="100%"
                >
                    <el-table-column
                        prop="id"
                        label="站点ID"
                        width="80"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="siteName"
                        label="站点名称"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="siteAddress"
                        label="站点地址"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="status"
                        label="状态"
                    >
                        <template v-slot="scope">
                            {{ formatState(scope.row.status) }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="createTime"
                        label="添加时间"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="orgName"
                        label="所属机构"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="id"
                        label="操作"
                        width="180"
                    >
                        <template v-slot="scope">
                            <DetailModal :fillData="scope.row">
                                <a class="operate-a">查看</a>
                            </DetailModal>
                            <a class="operate-a" @click="fillDataHandel(scope.row)">编辑</a>
                            <a class="operate-a" @click="deleteHandel(scope.row.id)">删除</a>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="table-page-container">
                <el-pagination
                    background
                    :current-page="page"
                    :page-sizes="[10, 20, 30, 40]"
                    :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="tableData.total"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                >
                </el-pagination>
            </div>
        </div>
        <AddSiteModal :dialogVisible="dialogVisible" :fillData="fillData" :beforeClose="dialogVisibleChangeHandel" :operateType="'edit'"/>
    </div>
</template>

<script>
import Tools from './components/Tools';
import './Index.less';
import axios from '@/utils/axios';
import AddSiteModal from './components/AddSiteModal';
import DetailModal from './components/DetailModal';
export default {
    name: 'SiteManagement',
    components: {
        Tools,
        AddSiteModal,
        DetailModal
    },
    data() {
        return {
            page: 1,
            pageSize: 20,
            params: {
                siteType: '',
                operationHeadman: '',
                orgId: '',
                status: '',
            },
            dialogVisible: false,
            fillData: { },
            titleData: {
                gangerGroupNum: 0,
                operationPersonNum: 0,
                siteNum: 0
            }
        }
    },
    computed: {
        tableData() {
            return this.$store.state.siteManagement.data;
        },
        currentOrgSiteCount() {
            const sites = this.tableData.records.filter(item => item.orgId === this.params.orgId);
            return sites.length;
        }
    },
    methods: {
        handleSizeChange(val) {
            this.pageSize = val;
            this.getData();
        },
        handleCurrentChange(val) {
            this.page = val;
            this.getData();
        },
        queryChangeHandel(params) {
            this.params = params;
            this.page = 1;
            this.getData();
        },
        formatState(status) {
            return status === 1 ? '有效' : '无效'
        },
        getData() {
            const {
                page,
                pageSize,
                params
            } = this;
            this.$store.dispatch({ type: 'siteManagement/fetchData', payload: { page, pageSize, params } });
        },
        deleteHandel(id) {
            this.$confirm('确认删除此站点, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                axios.post(`/api/site/remove/${id}`).then(
                    () => {
                        this.$message.success('删除成功');
                        this.getData();
                    }
                ).catch(err => {
                    this.$message.error(err);
                });
            });
        },
        fillDataHandel(record) {
            this.fillData = record;
            this.dialogVisible = true;
        },
        dialogVisibleChangeHandel() {
            this.dialogVisible = !this.dialogVisible;
        },
        getTitleCount() {
            const userinfo = localStorage.getItem('currentUser');
            if (userinfo) {
                const orgCode = JSON.parse(userinfo).org.orgCode;
                axios.get(`/api/org/getOrgStatisticsData/${orgCode}`).then(rs => {
                    this.titleData = rs;
                }).catch(err => {
                    this.$message.error(err);
                });
            }
        }
    },
    mounted() {
        this.getData();
        this.getTitleCount();
    }
}
</script>