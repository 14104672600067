<template>
    <el-dialog
        :title="operateType === 'add' ? '新增站点' : '编辑站点'"
        :visible.sync="dialogVisible"
        width="544px"
        :before-close="handleCloseHandel"
    >
        <div class="add-site-container">
            <el-form ref="addSiteForm" :model="siteInfo" :rules="rules" class="demo-form-inline">
                <el-form-item label="站点名称" :label-width="'90px'" prop="siteName">
                    <el-input v-model="siteInfo.siteName" placeholder="站点名称" :style="{width: '315px'}"></el-input>
                </el-form-item>
                <el-form-item label="站点类型" :label-width="'90px'" prop="siteType">
                    <el-select v-model="siteInfo.siteType" placeholder="站点类型" :style="{width: '315px'}">
                        <el-option label="自营" :value="1"></el-option>
                        <el-option label="联营" :value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="归属机构" :label-width="'90px'" prop="orgId">
                    <el-tree-select 
                        ref="group-select-tree"
                        class="group-select-tree-site" 
                        v-model="siteInfo.orgId"
                        :selectParams="selectParams" 
                        :treeParams="treeParams" 
                        :size="'small'"
                    >
                    </el-tree-select>
                </el-form-item>
                <el-form-item label="联系方式" :label-width="'90px'" prop="connectMobile">
                    <el-input v-model="siteInfo.connectMobile" placeholder="联系方式" :style="{width: '315px'}"></el-input>
                </el-form-item>
                <el-form-item label="站点地址" :label-width="'90px'" prop="siteAddress">
                    <el-input v-model="siteInfo.siteAddress" placeholder="站点地址" :style="{width: '315px'}"></el-input>
                </el-form-item>
                <el-form-item label="地图选点" :label-width="'90px'" prop="siteAddressLngLat">
                    <el-input v-model="siteInfo.siteAddressLngLat" placeholder="地图选点" :style="{width: '315px'}"></el-input>
                    <div class="site-map-container">
                        <SiteMap @pointChange="pointChangeHandel" :mapType="operateType"/>
                    </div>
                </el-form-item>
            </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="handleCloseHandel">取 消</el-button>
            <el-button type="primary" @click="addSiteHandel">确 定</el-button>
        </span>
    </el-dialog>
</template>

<script>
import SiteMap from './SiteMap';
import './AddSiteModal.less';
import axios from '@/utils/axios';
export default {
    name: 'AddSiteModal',
    components: {
        SiteMap
    },
    props: {
        dialogVisible: {
            type: Boolean,
            default: false
        },
        beforeClose: {
            type: Function,
            default: () => { }
        },
        fillData: {
            type: Object,
            default: () => ({})
        },
        operateType: {
            type: String,
            default: 'add'
        }
    },
    data() {
        return {
            siteInfo: {
                siteName: '',
                siteType: 1,
                orgId: '',
                connectMobile: '',
                siteAddress: '',
                siteAddressLngLat: ''
            },
            rules: {
                siteName: [
                    { required: true, message: '请输入站点名称', trigger: 'blur' }
                ],
                siteType: [
                    { required: true, message: '请选择站点类型', trigger: 'blur' }
                ],
                orgId: [
                    { required: true, message: '请选择归属机构', trigger: 'blur' }
                ],
                connectMobile: [
                    { required: true, message: '请输入联系方式', trigger: 'blur' }
                ],
                siteAddress: [
                    { required: true, message: '请输入站点地址', trigger: 'blur' }
                ],
                siteAddressLngLat: [
                    { required: true, message: '请选择经纬度', trigger: 'blur' }
                ],
            },
            selectParams: {
                multiple: false,
                clearable: true,
                placeholder: '请选择机构归属'
            },
            treeParams: {
                clickParent: true,
                filterable: false,
                'check-strictly': true,
                'default-expand-all': true,
                'expand-on-click-node': false,
                data: [],
                props: {
                    children: 'childs',
                    label: 'orgName',
                    value: 'orgId'
                }
            },
        }
    },
    watch: {
        fillData(val) {
            this.siteInfo = val;
        }
    },
    methods: {
        pointChangeHandel(point) {
            this.siteInfo.siteAddressLngLat = point.join(',');
        },
        addSiteHandel() {
            const {
                siteName,
                siteType,
                orgId,
                connectMobile,
                siteAddress,
                siteAddressLngLat
            } = this.siteInfo;
            if (this.operateType === 'add') {
                this.$refs.addSiteForm.validate((valid) => {
                    if (valid) {
                        axios.post('/api/site/create', {
                            siteName,
                            siteType,
                            orgId: Number(orgId),
                            connectMobile,
                            siteAddress,
                            siteAddressLngLat
                        }).then(() => {
                            this.$message.success('添加成功');
                            this.beforeClose();
                            this.$refs.addSiteForm.resetFields();
                            this.$refs.addSiteForm.clearValidate();
                        }).catch(err => {
                            this.$message.error(err);
                        });
                    }
                });
            } else {
                const id = this.fillData.id;
                this.$refs.addSiteForm.validate((valid) => {
                    if (valid) {
                        axios.post('/api/site/edit', {
                            id,
                            siteName,
                            siteType,
                            orgId: Number(orgId),
                            connectMobile,
                            siteAddress,
                            siteAddressLngLat
                        }).then(() => {
                            this.$message.success('编辑成功');
                            this.beforeClose();
                            this.$refs.addSiteForm.resetFields();
                            this.$refs.addSiteForm.clearValidate();
                        }).catch(err => {
                            this.$message.error(err);
                        });
                    }
                });
            }
        },
        handleCloseHandel() {
            this.beforeClose();
        },
        async getOrgData() {
            const userinfo = localStorage.getItem('currentUser')
            const orgId = JSON.parse(userinfo).orgId;
            const rs = await axios.get(`/api/org/getOrgTree/${Number(orgId)}`);
            this.treeParams.data = [rs]
        }
    },
    mounted() {
        this.getOrgData();
    }
}
</script>