<template>
    <span @click.stop="clickHandel">
        <slot></slot>
        <span @click.stop="">
            <el-dialog
                class="battery-detail-modal"
                title="站点详情"
                :visible.sync="dialogVisible"
                width="997px"
            >
                <div class="site-modal-detail-content">
                    <div :id="'siteMap'+fillData.id" class="top-map-container">
                    </div>
                    <div class="bottom-detail-container">
                        <div class="left-info-container">
                            <div class="info-item">
                                <span class="lable">站点名称：</span>
                                <span class="value">{{ fillData.siteName }}</span>
                            </div>
                            <div class="info-item">
                                <span class="lable">站点类型：</span>
                                <span class="value">{{ fillData.siteType === 1 ? '自营' : '联营' }}</span>
                            </div>
                            <div class="info-item">
                                <span class="lable">归属机构：</span>
                                <span class="value">{{ fillData.orgName }}</span>
                            </div>
                            <div class="info-item">
                                <span class="lable">联系方式：</span>
                                <span class="value">{{ fillData.connectMobile }}</span>
                            </div>
                            <div class="info-item">
                                <span class="lable">站点地址：</span>
                                <span class="value">{{ fillData.siteAddress }}</span>
                            </div>
                        </div>
                        <div class="right-info-container">
                            <el-table height="100%" :data="list">
                                <el-table-column label="运维班组" prop="gangerGroupName"></el-table-column>
                                <el-table-column label="班组长" prop="operationHeadmanName"></el-table-column>
                                <el-table-column label="工单总量" prop="jobCount"></el-table-column>
                                <el-table-column label="救援工单" prop="inspectionJobCount"></el-table-column>
                                <el-table-column label="巡检工单" prop="rescueJobCount"></el-table-column>
                            </el-table>
                        </div>
                    </div>
                </div>
            </el-dialog>
        </span>
    </span>
</template>

<script>
import L from "leaflet/dist/leaflet";
import "leaflet.chinatmsproviders";
import "leaflet/dist/leaflet.css";
import './DetailModal.less';
import axios from '@/utils/axios';
import icon from 'leaflet/dist/images/marker-icon.png';
export default {
    name: 'DetailModal',
    props: {
        fillData: {
            type: Object,
            default: () => ({
                id: '',
                siteName: '',
                siteType: '',
                orgName: '',
                connectMobile: '',
                siteAddress: '',
                siteAddressLngLat
            })
        }
    },
    data() {
        return {
            dialogVisible: false,
            map: null,
            list: []
        }
    },
    methods: {
        async clickHandel() {
            this.dialogVisible = true;
            this.$nextTick(async () => {
                this.initMap()
                const [lat, lng] = this.fillData.siteAddressLngLat.split(',');
                this.map.panTo([lat, lng]);
                this.pointHandel(lat, lng);
                const rs = await axios.get(`/api/site/getSiteDetailJobStatisticsData/${this.fillData.id}`);
                this.list = rs;
            });
        },
        initMap() {
            if (this.map) {
                return;
            }
            const normalm = L.tileLayer.chinaProvider("GaoDe.Normal.Map", {
                maxZoom: 18,
                minZoom: 1,
            });
            const normal = L.layerGroup([normalm]);
            const map = L.map(`siteMap${this.fillData.id}`, {
                zoom: 20,
                layers: [normal],
                zoomControl: false,
            });
            this.map = map;
        },
        pointHandel(lat, lng) {
            // 添加图标
            const myIcon = L.icon({
                iconUrl: icon,
            });
            if (this.point) {
                this.point.setLatLng([lat, lng]);
            } else {
                this.point = L.marker([lat, lng], { 
                    icon: myIcon
                });
            }
            this.point.addTo(this.map);
        }
    }
}
</script>